import { useEffect, useState } from 'react';

import { LogEvent } from '@analytics';
import { Carousel, CarouselApi, CarouselContent, CarouselItem, Label, Spacer } from 'design-system';
import Image from 'next/image';
import Link from 'next/link';
import { cn } from 'tailwind-config';

import { GuideSteps } from './constants';

interface GuideSliderProps {
  stepIndex: number;
  setStepIndex: (stepIndex: number) => void;
}

export const GuideSlider = ({ stepIndex, setStepIndex }: GuideSliderProps) => {
  const [api, setApi] = useState<CarouselApi>();

  useEffect(() => {
    if (!api) return;

    api.scrollTo(stepIndex);
  }, [stepIndex]);

  useEffect(() => {
    if (!api) {
      return;
    }

    if (stepIndex !== 0) {
      api.scrollTo(stepIndex);
    } else {
      setStepIndex(api.selectedScrollSnap());
    }

    api.on('select', () => {
      setStepIndex(api.selectedScrollSnap());
    });
  }, [api]);

  return (
    <Carousel
      setApi={setApi}
      opts={{ loop: false }}
      plugin={{ autoHeight: true }}
      className="-mx-16"
    >
      <CarouselContent>
        {GuideSteps.map((steps, index: number) => {
          return (
            <CarouselItem key={'steps-' + index} className="mr-0 w-full px-16">
              {steps.map((step, index: number) => {
                const { type } = step;
                if (type === 'level') {
                  const { level, title, body } = step;
                  return (
                    <div
                      key={index}
                      className={cn(
                        'rounded-[24px] bg-white px-20 py-24',
                        index !== 0 ? 'mt-12' : '',
                      )}
                    >
                      <Label label={`${level}단계`} styles="filled-yellow"></Label>
                      <Spacer className="h-8" />
                      <p className="text-new-Sub-Title">{title}</p>
                      <Spacer className="h-24" />
                      <div>
                        {body.map((bodyContents, index: number) => {
                          const { taskTitle, taskBody } = bodyContents;
                          return (
                            <div key={index} className="flex flex-col">
                              <div className="flex items-center gap-10">
                                <div className="bg-new-DTYellow-400 text-new-Caption1-bold h-20 w-20 rounded-full px-7 py-2">
                                  {index + 1}
                                </div>
                                <div className="text-new-Body1-bold text-gray-850 pt-2">
                                  {taskTitle}
                                </div>
                              </div>
                              <div className="flex gap-10">
                                <div
                                  className={cn(
                                    index !== body.length - 1
                                      ? 'border-new-gray-200 mx-9 my-8 border-l-2'
                                      : 'mx-10 my-8',
                                  )}
                                ></div>
                                <div
                                  className={cn(
                                    'w-full',
                                    taskBody !== '' && 'pt-12',
                                    index !== body.length - 1 && 'pb-28',
                                  )}
                                >
                                  {taskBody}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                } else if (type === 'banner') {
                  const { href, img, title, sub } = step;
                  return (
                    <Link
                      key={index}
                      className={cn(
                        'flex w-full cursor-pointer flex-col overflow-hidden rounded-[24px] bg-white',
                        index !== 0 ? 'mt-12' : '',
                      )}
                      href={href}
                      onClick={() => {
                        LogEvent.활성화.homeCTA(`가이드 - ${title}`);
                      }}
                    >
                      <div className="flex items-center justify-center object-cover">
                        <Image
                          src={img}
                          width={716}
                          height={368}
                          alt={'가이드 : ' + title}
                          quality={10}
                          priority={index === 0}
                          className="h-full w-full object-cover"
                        />
                      </div>
                      <div className="flex items-center justify-between bg-white px-20 py-16">
                        <div>
                          <p className="text-new-Section-Title">{title}</p>
                          <Spacer className="h-2" />
                          <p className="text-new-Body2-medium text-new-gray-500">{sub}</p>
                        </div>
                      </div>
                    </Link>
                  );
                }
              })}
            </CarouselItem>
          );
        })}
      </CarouselContent>
    </Carousel>
  );
};
